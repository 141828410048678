define('voronoi/router', ['exports', 'ember', 'voronoi/config/environment'], function (exports, _ember, _voronoiConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _voronoiConfigEnvironment['default'].locationType,
    rootURL: _voronoiConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('home', { path: "/" });
  });

  exports['default'] = Router;
});