define('voronoi/app', ['exports', 'ember', 'voronoi/resolver', 'ember-load-initializers', 'voronoi/config/environment'], function (exports, _ember, _voronoiResolver, _emberLoadInitializers, _voronoiConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _voronoiConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _voronoiConfigEnvironment['default'].podModulePrefix,
    Resolver: _voronoiResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _voronoiConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});