define('voronoi/components/voronoi-background', ['exports', 'ember', 'd3-selection', 'd3-array', 'd3-voronoi', 'd3-shape', 'd3-polygon', 'd3-color', 'd3-timer', 'd3-scale', 'd3-interpolate'], function (exports, _ember, _d3Selection, _d3Array, _d3Voronoi, _d3Shape, _d3Polygon, _d3Color, _d3Timer, _d3Scale, _d3Interpolate) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({

    ///////////////////////////////////////
    ////////  Component Defintion  ////////
    ///////////////////////////////////////

    tagName: 'canvas',
    attributeBindings: ['width', 'height'],

    //////////////////////////////////////
    ////////  Default Properties  ////////
    //////////////////////////////////////

    resizeWidthSensitive: true,
    resizeHeightSensitive: true,

    /////////////////////////////////////
    ////////  Lifecycle Methods  ////////
    /////////////////////////////////////

    didInsertElement: function didInsertElement() {
      var canvas = (0, _d3Selection.select)("canvas").node(),
          context = canvas.getContext("2d"),
          width = window.innerWidth,
          height = window.innerHeight;

      set(this, "width", width);
      set(this, "height", height);

      $(window).smartresize(function () {
        width = window.innerWidth;
        height = window.innerHeight;

        canvas.width = width;
        canvas.height = height;
        context.clearRect(0, 0, canvas.width, canvas.height);

        draw();
      });

      var _oldTimer;

      function draw() {
        if (_oldTimer) {
          _oldTimer.stop();
        }

        var sites = (0, _d3Array.range)(100).map(function () {
          return [Math.random() * width, Math.random() * height];
        }),
            cells = (0, _d3Voronoi.voronoi)().size([width, height]).polygons(sites),
            duration = 6000;

        var _color = (0, _d3Scale.scaleLinear)().domain([0, duration * 0.25, duration * 0.5, duration * 0.75, duration]).range(["#06040F", "#2A275D", "#784788", "#E49EBA", "#fdf6f3"]).interpolate(_d3Interpolate.interpolateRgb.gamma(1.5));

        var _timer = (0, _d3Timer.timer)(function (elapsed) {
          context.beginPath();

          cells.forEach(function (cell) {
            drawCell(cell);
            var p0 = cell.shift(),
                p1 = cell[0],
                t = Math.min(0.5, 4 / distance(p0, p1)),
                p2 = [p0[0] * (1 - t) + p1[0] * t, p0[1] * (1 - t) + p1[1] * t];
            cell.push(p2);
          });

          // console.log(_color(elapsed));
          var c = (0, _d3Color.color)(_color(elapsed)).darker(1).toString();
          context.fillStyle = c;
          context.fill();

          var xy = [Math.random() * width, Math.random() * height];

          context.beginPath();
          drawSite(xy);
          var starC = (0, _d3Color.color)("#b1adaa");
          starC.opacity = Math.random();
          context.fillStyle = starC;
          context.fill();

          if (elapsed > duration) _timer.stop();
        });
        _oldTimer = _timer;
      }

      draw();

      function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
      }

      function drawSite(site) {
        context.moveTo(site[0] + 2.5, site[1]);
        context.arc(site[0], site[1], getRandomArbitrary(0.5, 3), 0, 2 * Math.PI, false);
      }

      function drawCell(cell) {
        context.moveTo(cell[0][0], cell[0][1]);
        for (var i = 1, n = cell.length; i < n; ++i) context.lineTo(cell[i][0], cell[i][1]);
        context.closePath();
      }

      function distance(a, b) {
        var dx = a[0] - b[0],
            dy = a[1] - b[1];
        return Math.sqrt(dx * dx + dy * dy);
      }
    }

  });

  (function ($, sr) {

    // debouncing function from John Hann
    // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
    var debounce = function debounce(func, threshold, execAsap) {
      var timeout;

      return function debounced() {
        var obj = this,
            args = arguments;
        function delayed() {
          if (!execAsap) func.apply(obj, args);
          timeout = null;
        };

        if (timeout) clearTimeout(timeout);else if (execAsap) func.apply(obj, args);

        timeout = setTimeout(delayed, threshold || 100);
      };
    };
    // smartresize
    jQuery.fn[sr] = function (fn) {
      return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr);
    };
  })(jQuery, 'smartresize');
});